// src/App.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/login/login";
import Dashboard from "./components/pages/Dashboard";
import Products from "./components/pages/Products";
import Subscribers from "./components/pages/Subscribers";
import Layout from "./layout";
import Finance from "./components/pages/Finance";
import Team from "./components/pages/Team";
import Membership from "./components/pages/Membership";
import Banner from "./components/pages/Banner";
import Settings from "./components/pages/Settings";
import Role from "./components/pages/Role";
import ForgotPassword from "./components/login/forgotpassword";
import OTPVerify from "./components/login/otpverify";
import ChangePassword from "./components/login/changepassword";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./context/PrivateRoute";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="otpverify" element={<OTPVerify />} />
          <Route path="changepassword" element={<ChangePassword />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/layout/*" element={<PrivateRoute element={<Layout />} />}>
            <Route index path="dashboard" element={<Dashboard />} />
            <Route path="products" element={<Products />} />
            <Route path="subscribers" element={<Subscribers />} />
            <Route path="finance" element={<Finance />} />
            <Route path="Team" element={<Team />} />
            <Route path="Membership" element={<Membership />} />
            <Route path="Banner" element={<Banner />} />
            <Route path="Role" element={<Role />} />
            <Route path="Settings" element={<Settings />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
