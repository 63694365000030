import React, { useEffect, useRef, useState } from "react";
import closeIcon from "../../assets/dashboard/close.svg";
import TaskModal from "./taskmodal";
import "./modal.css";
import { callApi } from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { Hourglass } from "react-loader-spinner";
import { showError, showSuccess } from "../utils/sweetAlert";
import sendNotification from "../utils/FcmService";
interface ModalProps {
  isOpen: boolean;
  onClose: (type: any) => void;
  buttontext: string;
  selecteddata: object;
}
interface BusinessType {
  id: string;
  bussiness_name: string;
}
interface subscriberType {
  id: string;
  user_name: string;
}
interface role {
  id: number;
  role: string;
}
interface member {
  id: number;
  plan_name: string;
  duration: string;
  price: string;
  offer_price: string;
  plan_type: string;
  offer_enddate: string;
  offer_percentage: number;
  description: string;
  save_status: string;
  bussiness_type: string;
  image: string;
}

interface group {
  id: number;
  name: string;
  session: string;
  batch: string;
  bussiness_type: string;
  active_status: string;
  created_dt: string;
  image: string;
}

interface leads {
  id: number;
  name: string;
  phone_no: string;
  email: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  buttontext,
  selecteddata,
}) => {
  const [selectedButton, setSelectedButton] = useState<string>();
  const [formData, setFormData] = useState<any>({});
  const [proofFile, setProofFile] = useState<File | null>(null);
  const [showTaskModal, setShowTaskModal] = useState<boolean>(false);
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [lastClickedButton, setLastClickedButton] = useState<string | null>(
    null
  );
  const [admissionNo, setAdmissionNo] = useState("");
  const [isMultiSelectFocused, setIsMultiSelectFocused] = useState(false);
  const [selectedleaddata, setselectedleaddata] = useState<leads>({
    id: 0,
    name: "",
    phone_no: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [membershipdata, setmembershipdata] = useState<member[]>([]);
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");
  const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([]);
  const [roletypes, setroletypes] = useState<role[]>([]);
  const [subscribers, setsubsribers] = useState<subscriberType[]>([]);
  const [selectedplan, setselectedplan] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [emailcheck, setemailcheck] = useState<string>("");
  const [emailcheckbussiness, setemailcheckbussiness] = useState<string>("");
  const [emailuserid, setemailuserid] = useState<string>("");
  const [showEndDate, setShowEndDate] = useState(false);
  const [empnoError, setEmpNoerrror] = useState(false);
  const [selectedFormType, setSelectedFormType] = useState("");
  const [grouplist, setgrouplist] = useState<group[]>([]);
  const [startDate, setStartDate] = useState("");
  const [subscriberFormSubmitted, setSubscriberFormSubmitted] = useState<any>({
    businessType: "1",
  });
  useEffect(() => {
    setSelectedButton(buttontext);
    console.log("zzzzzzzzzzzzz", selectedButton);
  }, [buttontext]);

  useEffect(() => {
    console.log("subscriberFormSubmitted_____0", subscriberFormSubmitted);
  }, [subscriberFormSubmitted]);

  useEffect(() => {
    setFormData({
      ...selectedleaddata,
      ...selecteddata,
    });
  }, [isOpen === true]);
  useEffect(() => {
    getbussiness();

    const storedBusiness = localStorage.getItem("selectedBusiness");
    console.log("Selected Business1111:", storedBusiness);
    if (storedBusiness) {
      setSelectedBusiness(storedBusiness);
      getrole(storedBusiness);
      getsubscribers(storedBusiness);
      getmembership(storedBusiness);
      getgroup(storedBusiness);
    }
    if (selectedButton === "Subscriber") {
      getleads();
    }

    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      console.log("user id", storedUserId);
      setUserId(storedUserId);
    }
  }, []);

  const getbussiness = () => {
    callApi("GET", "bussiness_type.php")
      .then((response) => {
        setBusinessTypes(response.data.responseJson);
        if (response.data.responseJson.length > 0) {
          setSelectedBusiness(response.data.responseJson[0].id);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const getmembership = (businessType: string) => {
    const req = {
      bussiness_type: businessType,
    };
    callApi("POST", "membership_select.php", req)
      .then((response) => {
        console.log(" Response:", response.data.responseJson);
        setmembershipdata(response.data.responseJson);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const getgroup = (businessType: string) => {
    const req = {
      bussiness_type: businessType,
    };
    callApi("POST", "group_select.php", req)
      .then((response) => {
        const responseData = response.data.responseJson;
        if (responseData.length === 0) {
          console.log("No data available");
          // Handle the case when no data is found, e.g., display a message to the user
        } else {
          setgrouplist(responseData);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const getsubscribers = (businessType: string) => {
    const req = {
      bussiness_type: businessType,
    };
    callApi("POST", "get_all_subscribers.php", req)
      .then((response) => {
        const responseData = response.data.responseJson;
        if (responseData.length === 0) {
          console.log("No data available");
        } else {
          setsubsribers(response.data.responseJson);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const getleads = () => {
    const req = {
      bussiness_type: localStorage.getItem("selectedBusiness"),
    };
    callApi("POST", "lead_select.php", req)
      .then((response) => {
        const selecteditemid = localStorage.getItem("selectedItemid");
        console.log("qqqqqqqqqqqqq", selecteditemid);
        const filteredDataz = response.data.responseJson.filter(
          (item: any) => item.id === selecteditemid
        );
        if (filteredDataz.length > 0) {
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const getrole = (businessType: any) => {
    const req = {
      bussiness_type: businessType,
    };
    callApi("POST", "select_role.php", req)
      .then((response) => {
        setroletypes(response.data.responseJson);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const handleButtonClick = (buttonText: string) => {
    setSelectedButton(buttonText);
  };

  const validateEmail = (email: any) => {
    // Regular expression for validating email addresses with .com or .in domains
    const regex = /^[^\s@]+@[^\s@]+\.(com|in)$/;
    return regex.test(email);
  };

  const handleBlur = (event: any) => {
    const { id, value } = event.target;
    if (id === "email" && !validateEmail(value)) {
      alert("Please enter a valid email address.");
    }
  };

  const handleKeyPress = (event: any) => {
    const regex = /^[a-zA-Z0-9\s]*$/; // Regular expression to allow letters, numbers, and spaces
    if (!regex.test(event.key)) {
      event.preventDefault(); // Prevent input if the key doesn't match the regex
    }
  };

  const handleKeyPressname = (event: any) => {
    const regex = /^[a-zA-Z\s]*$/; // Regular expression to allow letters, numbers, and spaces
    if (!regex.test(event.key)) {
      event.preventDefault(); // Prevent input if the key doesn't match the regex
    }
  };

  const handleKeyDown = (event: any) => {
    // Allow only numeric characters (0-9), backspace, and delete
    if (
      !/^\d$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete"
    ) {
      event.preventDefault();
    }
  };

  const handleDecimal = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, currentTarget } = event;
    const { value } = currentTarget;

    if (
      (!/^[\dA-Za-z]$/.test(key) &&
        key !== "Backspace" &&
        key !== "Delete" &&
        key !== ".") ||
      (key === "." && value.includes("."))
    ) {
      event.preventDefault();
    }
  };

  const handleMultiSelectChange1 = (selectedsub: any) => {
    const subscriberIds = selectedsub.map((subscriber: any) => ({
      subscriber_id: subscriber.value,
    }));

    console.log("selected options", subscriberIds);
    setFormData({
      ...formData,
      subscriberIds,
    });
    // Handle selected options
  };

  const handleMultiSelectChange = (selectedsub: any) => {
    const subscriberIds = selectedsub.map(
      (subscriber: any) => subscriber.value
    );

    const groupIds = selectedsub.map((group: any) => group.value);

    console.log("selected options", subscriberIds);
    setFormData({
      ...formData,
      subscriberIds,
      groupIds,
    });
    // Handle selected options
  };
  const getMinDate = () => {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      today.getDate()
    );
    return minDate.toISOString().split("T")[0];
  };

  const handleBannerFileChange = (event: any) => {
    const file = event.target.files[0];
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      if (img.width !== 298 || img.height !== 53) {
        alert("Please select an image with dimensions 298px * 53px.");
        event.target.value = null;
      } else {
        // Proceed with handling the image
        handleFileChange(event);
      }
    };
  };
  const inputRef = useRef<HTMLInputElement>(null);
  const handleAdBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z][0-9]{1,5}$/;

    if (!regex.test(value)) {
      alert(
        "Invalid Admission Number. Please ensure it starts with a letter followed by 1 to 5 digits. Ex: A001"
      );
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    } else {
      setAdmissionNo(value);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { id, value } = e.target;

    if (id === "startdate") {
      setStartDate(value);
    }
    if (selectedButton === "Membership") {
      const durationStartWithZeroValid =
        id === "duration" && value.startsWith("0") ? true : false;
      const priceStartWithZeroValid =
        id === "price" && value.startsWith("0") ? true : false;
      const offpriceStartWithZeroValid =
        id === "offprice" && value.startsWith("0") ? true : false;
    }

    if (selectedButton === "User" && id === "email") {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      if (!isValidEmail) {
        console.log("error");
      } else {
        console.log("false");
      }
    }

    if (id === "offprice") {
      setShowEndDate(value !== "");
    }

    if (selectedButton === "Subscriber" && id === "email") {
      const req = { email: value };
      callApi("POST", "email_check.php", req)
        .then((response) => {
          const { plan_id, bussiness_type, id, bussiness_name } =
            response.data.response.data;
          setemailcheck(plan_id);
          setemailcheckbussiness(bussiness_type);
          setemailuserid(id);

          if (response.data.success === true) {
            const businessNamesArray = Object.values(bussiness_name);
            const allBusinessNames = businessNamesArray.join(", ");
            console.log("All Business Names:", allBusinessNames);

            let bussinessExists = false;

            for (const businessName of businessNamesArray) {
              console.log("Comparing:", businessName, "with", bussinessname);
              if (bussinessname === businessName) {
                bussinessExists = true;
                break;
              }
            }

            console.log("Business Exists:", bussinessExists);

            if (
              response.data.message ===
              "This Email Is Already Registerd As Staff"
            ) {
              const alertMessage = `${response.data.message}`;
              alert(alertMessage);
              setShowEmailModal(false);
              setFormData((prevData: any) => ({ ...prevData, email: "" }));
            }
            if (allBusinessNames.includes(bussinessname)) {
              const alertMessage = `${response.data.message}`;
              alert(alertMessage);
              setShowEmailModal(false);
            } else if (
              response.data.message === "This Email Is Already Registerd"
            ) {
              const confirmMessage = `${response.data.message} in ${allBusinessNames}\n\nDo you want to add user in ${bussinessname}?`;
              const proceed = window.confirm(confirmMessage);
              setShowEmailModal(proceed);
            }
            if (response.data.message === "Email Not exist") {
              console.log("test test");
              return;
            }
          }
        })
        .catch((error) => {
          console.error("Network error:", error);
        });
    }

    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];

    if (file.type.startsWith("image/")) {
      const fileExtension = file.name.split(".").pop();
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileDataUrl = reader.result;
        setFormData({
          ...formData,
          proof: fileDataUrl,
          imageFormat: fileExtension,
        });
      };
      reader.readAsDataURL(file);
    } else {
      const fileExtension = file.name.split(".").pop();
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileDataUrl = reader.result;
        setFormData({
          ...formData,
          file: fileDataUrl,
          reportFormat: fileExtension,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setSubscriberFormSubmitted({
      ...subscriberFormSubmitted,
      [id]: value,
    });
  };

  const handleEmailModalSubmit = (event: any) => {
    setLoading(true);
    event.preventDefault();
    const { plan, businessType, activationDate } = subscriberFormSubmitted;
    const storedUserId = localStorage.getItem("userId");
    const updatedPlanId = `${emailcheck},${plan}`;
    const updatedBussiness = `${emailcheckbussiness},${businessType}`;
    const req = {
      membership_plan: removeDuplicates(updatedPlanId),
      bussiness_type: removeDuplicates(updatedBussiness),
      plan_active_date: activationDate,
      user_id: emailuserid,
      updated_by: storedUserId,
    };
    callApi("POST", "add_business.php", req)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess("Success", response.data.message);
          setShowEmailModal(false);
          setLoading(false);
        } else {
          showError("Error..!", response.data.message);
          setShowEmailModal(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const removeDuplicates = (str: string): string => {
    return Array.from(new Set(str.split(","))).join(",");
  };
  const handlecloseeditmodal = () => {
    setShowEmailModal(false);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const formiddata = e.target.value;
    setSelectedFormType(formiddata);
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    console.log("select", formData);
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleSelectEmailChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSubscriberFormSubmitted({
      ...subscriberFormSubmitted,
      [e.target.id]: e.target.value,
    });
    console.log("select email", subscriberFormSubmitted);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (parseFloat(formData.offprice) >= parseFloat(formData.price)) {
      alert("Offer price must be less than the regular price.");

      return;
    }

    if (parseInt(formData.quantity) < parseInt(formData.minqty)) {
      alert("Quantity must not be less than the minimum quantity.");

      return;
    }
    if (selectedButton === "Product" && lastClickedButton === "Submit") {
      setLoading(true);
      const req = {
        product_name: formData.title,
        description: formData.description,
        size: formData.size,
        qty: formData.quantity,
        price: formData.price,
        offer_price: formData.offprice,
        low_qty: formData.minqty,
        bussiness_type: localStorage.getItem("selectedBusiness"),
        imageformat: formData.imageFormat,
        image: formData.proof,
        created_by: userId,
      };
      callApi("POST", "product_create.php", req)
        .then((response) => {
          console.log("API Response:", response.data);
          showSuccess("Success..!", response.data.message);
          onClose("close");
          setLoading(false);
          window.location.reload();
        })
        .catch((error) => {
          console.error("Network error:", error);
        });
    }
    if (selectedButton === "Subscriber" && lastClickedButton === "Submit") {
      setLoading(true);
      console.log("Subscriber", formData);
      const req = {
        name: formData.name,
        source_type: "1",
        lead_id: formData.id ? formData.id : "",
        email: formData.email,
        dob: formData.dob,
        phone_no: formData.phone_no,
        gender: formData.gender,
        blood_group: formData.blood,
        occupation: formData.occupation,
        plan_active_date: formData.doa,
        admission_no: admissionNo,
        membership_plan: formData.plantype,
        doj: formData.doj,
        address: formData.add,
        createdby: userId,
        bussiness_type: localStorage.getItem("selectedBusiness"),
        imageformat: formData.imageFormat,
        image: formData.proof,
        user_type: "2",
        height: formData.height,
        weight: formData.weight,
        bmi: formData.bmi,
        medical_history: formData.history,
        report: formData.file,
        reportFormat: formData.reportFormat,
      };
      callApi("POST", "user_create.php", req)
        .then((response) => {
          if (response.data.success === true) {
            showSuccess("Success..!", response.data.message);
            setLoading(false);
            window.location.reload();
            onClose("close");
          }
          if (response.data.message === "Admission Number Is Already Exist ") {
            alert(response.data.message);
            setLoading(false);
          }
          if (
            response.data.message === "This Email Is Already Registerd As Staff"
          ) {
            alert(response.data.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Network error:", error);
        });
    }
    if (selectedButton === "User" && lastClickedButton === "Submit") {
      setLoading(true);

      const req = {
        name: formData.fname,
        email: formData.email,
        dob: formData.dob,
        phone_no: formData.mobile,
        gender: formData.gender,
        blood_group: formData.blood,
        occupation: formData.btype,
        plan_active_date: formData.doj,
        admission_no: formData.addno,
        doj: formData.doj,
        address: formData.add,
        createdby: localStorage.getItem("userId"),
        bussiness_type: localStorage.getItem("selectedBusiness"),
        desigination: formData.designation,
        employee_no: formData.empno,
        imageformat: formData.imageFormat,
        image: formData.proof,
        user_type: "1",
        height: formData.height,
        weight: formData.weight,
        bmi: formData.bmi,
        medical_history: formData.history,
        report: formData.file,
        reportFormat: formData.reportFormat,
      };
      callApi("POST", "staff_create.php", req)
        .then((response) => {
          if (response.data.message === "Employee Number is Already Exist") {
            alert(response.data.message);
            setLoading(false);
          } else {
            showSuccess("Success..!", response.data.message);
            setLoading(false);
            window.location.reload();
            onClose("close");
          }
        })
        .catch((error) => {
          console.error("Network error:", error);
        });
    }
    if (selectedButton === "Membership" && lastClickedButton === "Submit") {
      if (formData.offprice != "0") {
        setLoading(true);
        const req = {
          plan_name: formData.title,
          occupation: formData.btype,
          description: formData.description,
          bussiness_type: localStorage.getItem("selectedBusiness"),
          imageformat: formData.imageFormat,
          image: formData.proof,
          duration: formData.duration,
          price: formData.price,
          offer_price: formData.offprice,
          plan_type: formData.plantype,
          subplan_type: formData.plantype,
          offer_enddate: formData.enddate,
          save_status: "0",
          created_by: userId,
        };
        callApi("POST", "plan_add.php", req)
          .then((response) => {
            if (response.data.message === "Plan Name Already Exist.") {
              alert(response.data.message);
              setLoading(false);
            }
            if (response.data.success === true) {
              showSuccess("Success..!", response.data.message);
              localStorage.setItem("selectedplanid", response.data.plan_id);

              setShowTaskModal(true);
              onClose("close");
              setLoading(false);
            }
          })
          .catch((error) => {
            console.error("Network error:", error);
          });
      } else {
        alert("Please enter a valid offer price.");
      }
    }
    if (selectedButton === "Membership" && lastClickedButton === "draft") {
      if (formData.offprice != "0") {
        setLoading(true);
        const req = {
          plan_name: formData.title,
          occupation: formData.btype,
          description: formData.description,
          bussiness_type: localStorage.getItem("selectedBusiness"),
          imageformat: formData.imageFormat,
          image: formData.proof,
          duration: formData.duration,
          price: formData.price,
          offer_price: formData.offprice,
          plan_type: formData.plantype,
          offer_enddate: formData.enddate,
          save_status: "0",
          created_by: userId,
        };
        callApi("POST", "plan_add.php", req)
          .then((response) => {
            if (response.data.success === true) {
              showSuccess("Success..!", response.data.message);
              localStorage.setItem("selectedplanid", response.data.plan_id);
              console.log("selectedplanid", response.data.plan_id);
              window.location.reload();
              onClose("close");
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.error("Network error:", error);
          });
      } else {
        alert("Please enter a valid offer price.");
      }
    }
    if (selectedButton === "Banner" && lastClickedButton === "Submit") {
      setLoading(true);

      const req = {
        title: formData.title,
        bussiness_types: localStorage.getItem("selectedBusiness"),
        start_date: formData.startdate,
        end_date: formData.enddate,
        imageformat: formData.imageFormat,
        status: "1",
        image: formData.proof,
        created_by: userId,
      };
      callApi("POST", "banner_insert.php", req)
        .then((response) => {
          if (response.data.success === true) {
            showSuccess("Success..!", response.data.message);
            setLoading(false);
            onClose("close");
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error("Network error:", error);
        });
    }
    if (selectedButton === "Notice" && lastClickedButton === "Submit") {
      setLoading(true);

      let recipients: string[] = [];

      if (formData.formtype === "1") {
        recipients = formData.subscriberIds;
      } else if (formData.formtype === "2") {
        recipients = formData.groupIds;
      } else {
        console.error("Invalid form type:", formData.formtype);
        return;
      }

      const req = {
        notice: formData.title,
        type: formData.formtype,
        bussiness_type: localStorage.getItem("selectedBusiness"),
        subscribers: recipients,
        message: formData.mess,
        created_by: userId,
      };
      callApi("POST", "add_notice_admin.php", req)
        .then((response) => {
          if (response.data.success === true) {
            showSuccess("Success..!", response.data.message);
            onClose("close");
            setLoading(false);
            getnotification(formData, recipients);
          }
        })
        .catch((error) => {
          console.error("Network error:", error);
        });
    }
    if (selectedButton === "Role" && lastClickedButton === "Submit") {
      setLoading(true);

      const storedBusiness = localStorage.getItem("selectedBusiness");

      const req = {
        name: formData.role,
        bussiness_type: localStorage.getItem("selectedBusiness"),
        created_by: userId,
      };
      callApi("POST", "role_create.php", req)
        .then((response) => {
          showSuccess("Success..!", response.data.message);
          setLoading(false);
          onClose("close");
          getrole(localStorage.getItem("selectedBusiness"));
          window.location.reload();
        })
        .catch((error) => {
          console.error("Network error:", error);
        });
    }
    if (selectedButton === "Group" && lastClickedButton === "Submit") {
      setLoading(true);

      const req = {
        group_name: formData.title,
        session: formData.session,
        batch: formData.batch,
        subscribers: formData.subscriberIds,
        bussiness_type: localStorage.getItem("selectedBusiness"),
        created_by: userId,
      };
      callApi("POST", "group_insert.php", req)
        .then((response) => {
          showSuccess("Success..!", response.data.message);
          getgroup(selectedBusiness);

          onClose("close");
        })
        .catch((error) => {
          console.error("Network error:", error);
        });
    }
    if (selectedButton === "Leads" && lastClickedButton === "Submit") {
      setLoading(true);

      const req = {
        name: formData.name,
        email: formData.email,
        phone_no: formData.mobile,
        bussiness_type: localStorage.getItem("selectedBusiness"),
        created_by: userId,
      };
      callApi("POST", "lead_create.php", req)
        .then((response) => {
          showSuccess("Success..!", response.data.message);
          onClose("close");
          window.location.reload();
          setLoading(false);
        })
        .catch((error) => {
          console.error("Network error:", error);
        });
    }
  };

  const getnotification = (formdata: any, subids: any) => {
    console.log("vvvvvvvvvvv", subids);
    const req = {
      id: subids,
      status: formdata.formtype,
    };
    callApi("POST", "notification.php", req)
      .then((response) => {
        console.log("e3434343f''''''''''''", response.data);
        if (response.data.success === true) {
          let authoritys_token = response.data.responseJson.token;
          for (let i = 0; i < authoritys_token.length; i++) {
            console.log("authoritys_token", authoritys_token[i]);
            sendNotification(
              authoritys_token[i],
              formdata.title,
              formdata.mess
            );
          }
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const handleButtonClickInsideForm = (buttonText: string) => {
    setLastClickedButton(buttonText);
  };
  const handleSaveDraft = () => {
    console.log("Form Data saved as draft:", formData);
  };
  const handleCloseTaskModal = (type: any) => {
    setShowTaskModal(false);
    console.log(type);
    if (type == "savedraft") {
      onClose("savedraft");
    }
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: "50%",
      height: "3rem",
      backgroundColor: "#f0f0f0",
    }),
    menu: (provided: any) => ({
      ...provided,
      width: "50%",
    }),

    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "lightblue", // Set your desired background color for selected values
      maxWidth: "90%", // Set maximum width to ensure it doesn't overflow
      overflow: "hidden", // Hide any overflow content
      whiteSpace: "nowrap", // Prevent wrapping of content
      textOverflow: "ellipsis", // Show ellipsis for truncated content
    }),
  };

  const today = new Date();
  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() + 90);
  const formattedMaxDate = maxDate.toISOString().split("T")[0];

  const bussinessname = localStorage.getItem("Businessname") || "";

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <Hourglass visible={true} height={50} width={50} />
          </div>
        </div>
      )}
      <ToastContainer />
      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={onClose}>
              <img src={closeIcon} alt="Close" />
            </button>
            <form onSubmit={handleSubmit}>
              {selectedButton && (
                <div className="selected-fields">
                  {selectedButton === "User" && <p>Create New User</p>}
                  {selectedButton === "Subscriber" && (
                    <p>Create New Subscriber</p>
                  )}
                  {selectedButton === "Product" && <p>Create New Product</p>}
                  {selectedButton === "Membership" && (
                    <p>Create New Membership</p>
                  )}
                  {selectedButton === "Notice" && <p>Create New Notice</p>}
                  {selectedButton === "Group" && <p>Create New Group</p>}
                  {selectedButton === "Banner" && <p>Create New Banner</p>}
                  {selectedButton === "Role" && <p>Create New Role</p>}
                  {selectedButton === "Leads" && <p>Create New Leads</p>}
                </div>
              )}
              <div className="cardmodal">
                {[
                  "Subscriber",
                  "Product",
                  "Membership",
                  "User",
                  "Group",
                  "Notice",
                  "Banner",
                  "Role",
                  "Leads",
                ].map((buttonText) => (
                  <button
                    key={buttonText}
                    className={selectedButton === buttonText ? "active" : ""}
                    onClick={() => handleButtonClick(buttonText)}
                  >
                    {buttonText}
                  </button>
                ))}
              </div>
              {selectedButton && (
                <div className="selected-fields">
                  {selectedButton === "Product" && (
                    <div className="product-fields">
                      <div className="input-row">
                        <div>
                          <label htmlFor="title">
                            Title:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="title"
                            onChange={handleChange}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault(); // Prevent the default behavior of Enter key
                              }
                              handleKeyPress(e); // Call your existing key press handler
                            }}
                            required
                            maxLength={50}
                          />
                        </div>
                        <div>
                          <label htmlFor="quantity">
                            Quantity:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="quantity"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            maxLength={10}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="price">
                            Price:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            id="price"
                            min="0"
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="offprice">Offer Price:</label>
                          <input
                            type="number"
                            id="offprice"
                            min="0"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="add">
                            Minimum Qty:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="minqty"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            minLength={0}
                            maxLength={10}
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="size">
                            Size:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            placeholder="Ex: 40Kg"
                            type="text"
                            id="size"
                            onKeyPress={handleKeyPress}
                            onChange={handleChange}
                            minLength={0}
                            maxLength={30}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="description">
                            Description:<span style={{ color: "red" }}>*</span>
                          </label>
                          <textarea
                            id="description"
                            onChange={handleChange}
                            minLength={1}
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="proof">
                            Image Upload:<span style={{ color: "red" }}>*</span>
                          </label>{" "}
                          <input
                            type="file"
                            id="proof"
                            accept="image/*"
                            onChange={handleFileChange}
                            required
                          />{" "}
                        </div>
                      </div>
                      <div className="notmem">
                        <button
                          type="submit"
                          className="submit-button"
                          onClick={() => handleButtonClickInsideForm("Submit")}
                          disabled={loading}
                        >
                          {loading ? (
                            <Hourglass
                              visible={true}
                              height="20"
                              width="20"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              colors={["white", "white"]}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                  {selectedButton === "User" && (
                    <div className="product-fields">
                      <div className="input-row">
                        <div>
                          <label htmlFor="fname">
                            Full Name:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="fname"
                            onChange={handleChange}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault(); // Prevent the default behavior of Enter key
                              }
                              handleKeyPressname(e); // Call your existing key press handler
                            }}
                            required
                            maxLength={40}
                            minLength={1}
                          />
                        </div>
                        <div>
                          <label htmlFor="email">
                            Email:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            minLength={6}
                            maxLength={40}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="dob">
                            Date of Birth:
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input type="date" id="dob" onChange={handleChange} />
                        </div>
                        <div>
                          <label htmlFor="mobile">
                            Phone Number:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="mobile"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            minLength={10}
                            maxLength={10}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="gender">
                            Gender:<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{
                              width: "106%",
                              height: "69%",
                              borderRadius: "5px",
                            }}
                            id="gender"
                            onChange={handleSelectChange}
                            required
                          >
                            <option value="">Select Gender</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                            <option value="T">Other</option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="blood">Blood Group:</label>
                          <select
                            id="blood"
                            onChange={handleChange}
                            style={{
                              width: "106%",
                              height: "50px",
                              borderRadius: "5px",
                            }}
                          >
                            <option value="">Select Blood Group</option>
                            <option value="A+">A+</option>
                            <option value="A-">A-</option>
                            <option value="B+">B+</option>
                            <option value="B-">B-</option>
                            <option value="AB+">AB+</option>
                            <option value="AB-">AB-</option>
                            <option value="O+">O+</option>
                            <option value="O-">O-</option>
                            {/* Add more blood groups as needed */}
                          </select>
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="height">
                            Height:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="height"
                            onChange={handleChange}
                            onKeyDown={handleDecimal}
                            required
                            minLength={2}
                          />
                        </div>
                        <div>
                          <label htmlFor="weight">
                            Weight:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="weight"
                            onChange={handleChange}
                            onKeyDown={handleDecimal}
                            minLength={2}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="bmi">
                            BMI:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="bmi"
                            onChange={handleChange}
                            onKeyDown={handleDecimal}
                            required
                            minLength={2}
                          />
                        </div>
                        <div>
                          <label htmlFor="history">Medical History:</label>
                          <input
                            type="text"
                            id="history"
                            onChange={handleChange}
                            minLength={1}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="file">Medical Report Upload:</label>{" "}
                          <input
                            type="file"
                            id="file"
                            accept="pdf/*"
                            onChange={handleFileChange}
                          />{" "}
                        </div>
                        <div>
                          <label htmlFor="designationbtype">
                            Business type:
                          </label>
                          <input
                            type="text"
                            id="designationbtype"
                            onChange={handleChange}
                            readOnly
                            value={bussinessname}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="designation">
                            Designation/Role
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="designation"
                            style={{
                              width: "106%",
                              height: "80%",
                              borderRadius: "5px",
                            }}
                            onChange={handleSelectChange}
                            required
                          >
                            <option value="">Select </option>
                            {roletypes.map((role) => (
                              <option key={role.id} value={role.id}>
                                {role.role.toUpperCase()}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label htmlFor="empno">
                            Employee No:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="empno"
                            onChange={handleChange}
                            maxLength={20}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="doj">
                            Date of Joining:
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            id="doj"
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="add">
                            Full Address:<span style={{ color: "red" }}>*</span>
                          </label>
                          <textarea
                            id="add"
                            onChange={handleChange}
                            required
                            minLength={3}
                            maxLength={250}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="proof">Proof Upload:</label>{" "}
                          <input
                            type="file"
                            id="proof"
                            accept="image/*"
                            onChange={handleFileChange}
                          />{" "}
                        </div>
                      </div>
                      <div className="notmem">
                        <button
                          type="submit"
                          className="submit-button"
                          onClick={() => handleButtonClickInsideForm("Submit")}
                          disabled={loading}
                        >
                          {loading ? (
                            <Hourglass
                              visible={true}
                              height="20"
                              width="20"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              colors={["white", "white"]}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>{" "}
                    </div>
                  )}
                  {selectedButton === "Membership" && (
                    <div className="product-fields">
                      <div className="input-row">
                        <div>
                          <label htmlFor="title">
                            Title:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="title"
                            onChange={handleChange}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault(); // Prevent the default behavior of Enter key
                              }
                              // handleKeyPressname(e); // Call your existing key press handler
                            }}
                            minLength={3}
                            maxLength={250}
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="duration">
                            Duration:In Days
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="duration"
                            onKeyDown={handleKeyDown}
                            onChange={handleChange}
                            min={1}
                            maxLength={5}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="price">
                            Price:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            id="price"
                            onChange={handleChange}
                            min={3}
                            maxLength={8}
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="offprice">Offer Price:</label>
                          <input
                            type="number"
                            id="offprice"
                            onChange={handleChange}
                            maxLength={8}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="plantype">
                            Plan Type:<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{
                              width: "106%",
                              height: "80%",
                              borderRadius: "5px",
                            }}
                            id="plantype"
                            onChange={handleSelectChange}
                            required
                          >
                            <option value="">Select plantype</option>
                            <option value="1">PT</option>
                            <option value="2">Home PT</option>
                            <option value="3">1 Month</option>
                            <option value="4">3 Month</option>
                            <option value="5">6 Month</option>
                            <option value="6">12 Month</option>
                            <option value="7">3 + 3</option>
                            <option value="8">6 + 6</option>
                            <option value="9">1 + 1</option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="description">
                            Description:<span style={{ color: "red" }}>*</span>
                          </label>
                          <textarea
                            id="description"
                            minLength={3}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        {showEndDate && (
                          <div>
                            <label htmlFor="enddate">
                              Offer End Date:
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="date"
                              id="enddate"
                              min={getCurrentDate()}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        )}
                        <div>
                          <label htmlFor="proof">
                            Image Upload:<span style={{ color: "red" }}>*</span>
                          </label>{" "}
                          <input
                            type="file"
                            id="proof"
                            accept="image/*"
                            onChange={handleFileChange}
                            required
                          />{" "}
                        </div>
                      </div>
                      <div className="button-row">
                        <button
                          type="submit"
                          className="draft-button"
                          onClick={() => handleButtonClickInsideForm("draft")}
                        >
                          Save as Draft
                        </button>
                        <button
                          type="submit"
                          className="submit-button"
                          style={{ marginTop: "0px", marginRight: "0" }}
                          onClick={() => handleButtonClickInsideForm("Submit")}
                          disabled={loading}
                        >
                          {loading ? (
                            <Hourglass
                              visible={true}
                              height="20"
                              width="20"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              colors={["white", "white"]}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                  {selectedButton === "Notice" && (
                    <div className="product-fields">
                      <div className="input-row">
                        <div>
                          <label htmlFor="title">
                            Title:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="title"
                            minLength={1}
                            maxLength={20}
                            onChange={handleChange}
                            required
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault(); // Prevent the default behavior of Enter key
                              }
                              handleKeyPressname(e); // Call your existing key press handler
                            }}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="formtype">
                            Form Type:<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{
                              width: "106%",
                              height: "80%",
                              borderRadius: "5px",
                            }}
                            id="formtype"
                            onChange={handleSelectChange}
                            required
                          >
                            <option value="">Select </option>
                            <option value="1">Individual</option>
                            <option value="2">Group</option>
                          </select>
                        </div>
                      </div>
                      {selectedFormType === "1" && (
                        <div>
                          <div>
                            {" "}
                            <div>
                              <label htmlFor="subscribers">
                                Subscribers:
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Select
                                isMulti
                                closeMenuOnSelect={false}
                                styles={{
                                  control: (provided: any) => ({
                                    ...provided,
                                    backgroundColor: "#f0f0f0",
                                  }),

                                  multiValue: (provided: any) => ({
                                    ...provided,
                                    backgroundColor: "lightblue", // Set maximum width to ensure it doesn't overflow
                                    overflow: "hidden", // Hide any overflow content
                                    whiteSpace: "nowrap", // Prevent wrapping of content
                                    textOverflow: "ellipsis", // Show ellipsis for truncated content
                                  }),
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                    width: "40%",
                                  }),

                                  menu: (base) => ({
                                    ...base,
                                    marginTop: "0", // Remove default margin
                                    borderRadius: "5px",
                                    boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
                                    border: "1px solid #ccc",
                                    width: "50%",
                                    height: isMultiSelectFocused
                                      ? "auto"
                                      : "30px",
                                    overflowY: "auto",
                                  }),
                                }}
                                menuPortalTarget={document.body}
                                onFocus={() => setIsMultiSelectFocused(true)}
                                onBlur={() => setIsMultiSelectFocused(false)}
                                options={subscribers.map((subscriber) => ({
                                  value: subscriber.id,
                                  label: subscriber.user_name,
                                }))}
                                onChange={handleMultiSelectChange}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {selectedFormType === "2" && (
                        <div>
                          <div>
                            {" "}
                            <div>
                              <label htmlFor="subscribers">
                                Group:
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Select
                                isMulti
                                closeMenuOnSelect={false}
                                styles={{
                                  control: (provided: any) => ({
                                    ...provided,
                                    backgroundColor: "#f0f0f0",
                                  }),

                                  multiValue: (provided: any) => ({
                                    ...provided,
                                    backgroundColor: "lightblue", // Set maximum width to ensure it doesn't overflow
                                    overflow: "hidden", // Hide any overflow content
                                    whiteSpace: "nowrap", // Prevent wrapping of content
                                    textOverflow: "ellipsis", // Show ellipsis for truncated content
                                  }),
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                    width: "40%",
                                  }),

                                  menu: (base) => ({
                                    ...base,
                                    marginTop: "0", // Remove default margin
                                    borderRadius: "5px",
                                    boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
                                    border: "1px solid #ccc",
                                    width: "50%",
                                    height: isMultiSelectFocused
                                      ? "auto"
                                      : "30px",
                                    overflowY: "auto",
                                  }),
                                }}
                                menuPortalTarget={document.body}
                                options={subscribers.map((subscriber) => ({
                                  value: subscriber.id,
                                  label: subscriber.user_name,
                                }))}
                                onFocus={() => setIsMultiSelectFocused(true)}
                                onBlur={() => setIsMultiSelectFocused(false)}
                                onChange={handleMultiSelectChange}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="input-row">
                        <div>
                          <label htmlFor="mess">Write Message:</label>
                          <textarea
                            id="mess"
                            onChange={(
                              e: React.ChangeEvent<HTMLTextAreaElement>
                            ) => handleChange(e)}
                          />
                        </div>
                      </div>
                      <div className="notmem">
                        <button
                          type="submit"
                          className="submit-button"
                          onClick={() => handleButtonClickInsideForm("Submit")}
                          disabled={loading}
                        >
                          {loading ? (
                            <Hourglass
                              visible={true}
                              height="20"
                              width="20"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              colors={["white", "white"]}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                  {selectedButton === "Banner" && (
                    <div className="product-fields">
                      <div className="input-row">
                        <div>
                          <label htmlFor="title">
                            Title:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="title"
                            onChange={handleChange}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault(); // Prevent the default behavior of Enter key
                              }
                              handleKeyPress(e); // Call your existing key press handler
                            }}
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="startdate">
                            Start Date:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            min={getCurrentDate()}
                            id="startdate"
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="enddate">
                            End Date:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            id="enddate"
                            min={startDate}
                            onChange={handleChange}
                            max={formattedMaxDate}
                            required
                          />
                        </div>{" "}
                        <div>
                          <label htmlFor="proof">
                            Image Upload: 298px * 53px
                            <span style={{ color: "red" }}>*</span>
                          </label>{" "}
                          <input
                            type="file"
                            id="proof"
                            accept="image/*"
                            onChange={handleBannerFileChange}
                            required
                          />{" "}
                        </div>
                      </div>
                      <div className="notmem">
                        <button
                          type="submit"
                          className="submit-button"
                          onClick={() => handleButtonClickInsideForm("Submit")}
                          disabled={loading}
                        >
                          {loading ? (
                            <Hourglass
                              visible={true}
                              height="20"
                              width="20"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              colors={["white", "white"]}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>{" "}
                    </div>
                  )}
                  {selectedButton === "Subscriber" && (
                    <div className="product-fields">
                      <div className="input-row">
                        <div>
                          <label htmlFor="addno">
                            Admission No:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="addno"
                            onBlur={handleAdBlur}
                            ref={inputRef}
                            required
                            style={{ height: "30px" }}
                            maxLength={20}
                          />
                        </div>
                        <div>
                          <label htmlFor="plantype">
                            Plan Type:<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="plantype"
                            style={{
                              width: "106%",
                              height: "80%",
                              borderRadius: "5px",
                            }}
                            onChange={handleSelectChange}
                            required
                          >
                            <option value="">Select plantype</option>
                            <option value="1">PT</option>
                            <option value="2">Home PT</option>
                            <option value="3">1 Month</option>
                            <option value="4">3 Month</option>
                            <option value="5">6 Month</option>
                            <option value="6">12 Month</option>
                            <option value="7">3 + 3</option>
                            <option value="8">6 + 6</option>
                            <option value="9">1 + 1</option>
                          </select>
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="doj">
                            Date of Activation:
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            id="doa"
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="doj">
                            Date of Joining:
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            id="doj"
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="designationbtype">
                            Business type:
                          </label>
                          <input
                            type="text"
                            id="designationbtype"
                            onChange={handleChange}
                            readOnly
                            value={bussinessname}
                          />
                        </div>
                        <div>
                          <label htmlFor="fname">
                            Full Name:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            onKeyPress={handleKeyPressname}
                            maxLength={40}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="mobile">
                            Phone Number:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="phone_no"
                            value={formData.phone_no}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            minLength={10}
                            maxLength={10}
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="add">
                            Full Address:<span style={{ color: "red" }}>*</span>
                          </label>
                          <textarea
                            id="add"
                            onChange={handleChange}
                            required
                            style={{ borderRadius: "5px" }}
                            minLength={3}
                            maxLength={250}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="dob">Date of Birth:</label>
                          <input type="date" id="dob" onChange={handleChange} />
                        </div>
                        <div>
                          <label htmlFor="blood">Blood Group:</label>
                          <select
                            id="blood"
                            onChange={handleChange}
                            style={{
                              width: "106%",
                              height: "50px",
                              borderRadius: "5px",
                            }}
                          >
                            <option value="">Select Blood Group</option>
                            <option value="A+">A+</option>
                            <option value="A-">A-</option>
                            <option value="B+">B+</option>
                            <option value="B-">B-</option>
                            <option value="AB+">AB+</option>
                            <option value="AB-">AB-</option>
                            <option value="O+">O+</option>
                            <option value="O-">O-</option>
                            {/* Add more blood groups as needed */}
                          </select>
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="occupation">
                            Occupation:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="occupation"
                            onChange={handleChange}
                            required
                            onKeyPress={handleKeyPressname}
                            maxLength={20}
                          />
                        </div>
                        <div>
                          <label htmlFor="email">
                            Email:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength={30}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="gender">
                            Gender:<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{
                              width: "106%",
                              height: "80%",
                              borderRadius: "5px",
                            }}
                            id="gender"
                            onChange={handleSelectChange}
                            required
                          >
                            <option value="">Select Gender</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                            <option value="T">Other</option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="height">
                            Height:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="height"
                            onChange={handleChange}
                            onKeyDown={handleDecimal}
                            required
                            minLength={2}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="weight">
                            Weight:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="weight"
                            onChange={handleChange}
                            onKeyDown={handleDecimal}
                            minLength={2}
                          />
                        </div>
                        <div>
                          <label htmlFor="bmi">
                            BMI:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="bmi"
                            onChange={handleChange}
                            required
                            minLength={2}
                            onKeyDown={handleDecimal}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="file">Medical Report Upload:</label>{" "}
                          <input
                            type="file"
                            id="file"
                            accept="pdf/*"
                            onChange={handleFileChange}
                          />{" "}
                        </div>
                        <div>
                          <label htmlFor="history">Medical History:</label>
                          <input
                            type="text"
                            id="history"
                            onChange={handleChange}
                            minLength={1}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="proof">Proof Upload:</label>{" "}
                          <input
                            type="file"
                            id="proof"
                            accept="image/*"
                            onChange={handleFileChange}
                          />{" "}
                        </div>
                      </div>
                      <div className="notmem">
                        <button
                          type="submit"
                          className="submit-button"
                          onClick={() => handleButtonClickInsideForm("Submit")}
                          disabled={loading}
                        >
                          {loading ? (
                            <Hourglass
                              visible={true}
                              height="20"
                              width="20"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              colors={["white", "white"]}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>{" "}
                    </div>
                  )}
                  {selectedButton === "Group" && (
                    <div className="product-fields">
                      <div className="input-row">
                        <div>
                          <label htmlFor="title">
                            Title:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="title"
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            required
                            maxLength={30}
                          />
                        </div>
                        <div>
                          <label htmlFor="session">
                            Session:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="session"
                            onChange={handleChange}
                            required
                            maxLength={20}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="batch">
                            Batch:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="batch"
                            onChange={handleChange}
                            required
                            maxLength={20}
                          />
                        </div>
                      </div>
                      <div>
                        {" "}
                        <div>
                          <label htmlFor="subscribers">
                            Subscribers:<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            isMulti
                            closeMenuOnSelect={false}
                            styles={{
                              control: (provided: any) => ({
                                ...provided,
                                backgroundColor: "#f0f0f0",
                              }),

                              multiValue: (provided: any) => ({
                                ...provided,
                                backgroundColor: "lightblue", // Set maximum width to ensure it doesn't overflow
                                overflow: "hidden", // Hide any overflow content
                                whiteSpace: "nowrap", // Prevent wrapping of content
                                textOverflow: "ellipsis", // Show ellipsis for truncated content
                              }),
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                                width: "40%",
                              }),

                              menu: (base) => ({
                                ...base,
                                marginTop: "0", // Remove default margin
                                borderRadius: "5px",
                                boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
                                border: "1px solid #ccc",
                                width: "50%",
                                height: isMultiSelectFocused ? "auto" : "30px",
                                overflowY: "auto",
                              }),
                            }}
                            menuPortalTarget={document.body}
                            options={subscribers.map((subscriber) => ({
                              value: subscriber.id,
                              label: subscriber.user_name,
                            }))}
                            onFocus={() => setIsMultiSelectFocused(true)}
                            onBlur={() => setIsMultiSelectFocused(false)}
                            onChange={handleMultiSelectChange1}
                            required
                          />
                        </div>
                      </div>
                      <div className="notmem">
                        <button
                          type="submit"
                          className="submit-button"
                          onClick={() => handleButtonClickInsideForm("Submit")}
                          disabled={loading}
                        >
                          {loading ? (
                            <Hourglass
                              visible={true}
                              height="20"
                              width="20"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              colors={["white", "white"]}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>{" "}
                    </div>
                  )}
                  {selectedButton === "Role" && (
                    <div className="product-fields">
                      <div className="input-row">
                        <div>
                          <label htmlFor="title">
                            Role Title:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="role"
                            onChange={handleChange}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault(); // Prevent the default behavior of Enter key
                              }
                              handleKeyPressname(e); // Call your existing key press handler
                            }}
                            required
                            maxLength={50}
                          />
                        </div>
                        <div>
                          <label htmlFor="title">Bussiness Type:</label>
                          <input
                            readOnly
                            type="text"
                            id="btype"
                            value={bussinessname}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="notmem">
                        <button
                          type="submit"
                          className="submit-button"
                          onClick={() => handleButtonClickInsideForm("Submit")}
                          disabled={loading}
                        >
                          {loading ? (
                            <Hourglass
                              visible={true}
                              height="20"
                              width="20"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              colors={["white", "white"]}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>{" "}
                    </div>
                  )}
                  {selectedButton === "Leads" && (
                    <div className="product-fields">
                      <div className="input-row">
                        <div>
                          <label htmlFor="title">
                            Full Name:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="name"
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            required
                            maxLength={30}
                          />
                        </div>
                        <div>
                          <label htmlFor="email">
                            Email:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            minLength={6}
                            maxLength={40}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div>
                          <label htmlFor="mobile">
                            Phone Number:<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="mobile"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            minLength={10}
                            maxLength={10}
                            required
                          />
                        </div>
                      </div>
                      <div className="notmem">
                        <button
                          type="submit"
                          className="submit-button"
                          onClick={() => handleButtonClickInsideForm("Submit")}
                          disabled={loading}
                        >
                          {loading ? (
                            <Hourglass
                              visible={true}
                              height="20"
                              width="20"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              colors={["white", "white"]}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>{" "}
                    </div>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      )}
      {showTaskModal && <TaskModal onClose={handleCloseTaskModal} />}
      {showEmailModal && (
        <div className="email-modal">
          <button className="close-button" onClick={handlecloseeditmodal}>
            <img src={closeIcon} alt="Close" />
          </button>
          <form onSubmit={handleEmailModalSubmit}>
            <div className="input-row">
              <div>
                <label htmlFor="businessType">Business Type:</label>
                <select
                  id="businessType"
                  value={selectedBusiness}
                  aria-readonly
                  onChange={handleSelectEmailChange}
                  style={{
                    width: "100%",
                    height: "2rem",
                    borderRadius: "5px",
                  }}
                >
                  <option value="">Select </option>
                  {businessTypes.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.bussiness_name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="plan">Plan:</label>
                <select
                  id="plan"
                  onChange={handleSelectEmailChange}
                  style={{
                    width: "100%",
                    height: "2rem",
                    borderRadius: "5px",
                  }}
                >
                  <option value="">Select </option>
                  {membershipdata.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.plan_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="input-row">
              <div>
                <label htmlFor="activationDate">Activation Date:</label>
                <input
                  type="date"
                  id="activationDate"
                  onChange={handleEmailChange}
                  style={{
                    width: "92%",
                    height: "1rem",
                    borderRadius: "5px",
                  }}
                />
              </div>
            </div>
            <div className="button-row">
              <button
                type="submit"
                className="submit-button"
                disabled={loading}
              >
                {loading ? (
                  <Hourglass
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="hourglass-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    colors={["white", "white"]}
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default Modal;
