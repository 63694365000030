import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./login.css";
import loginoimg from "../../assets/login1.svg";
import logo from "../../assets/login-logo.svg";
import { useNavigate } from "react-router-dom";
import { callApi } from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Hourglass } from "react-loader-spinner";
import { showError, showSuccess } from "../utils/sweetAlert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../../context/AuthContext";
const Login: React.FC = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [userId, setUserId] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId"); // Retrieve userId from localStorage
    console.log("test test", storedUserId);

    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    let valid = true;

    // Validation checks
    if (email.trim() === "") {
      setEmailError("User Name is required");
      valid = false;
    }
    if (password.trim() === "") {
      setPasswordError("Password is required");
      valid = false;
    }

    if (valid) {
      const req = {
        username: email,
        password: password,
      };

      callApi("POST", "admin_login.php", req)
        .then((res) => res.data)
        .then(async (response) => {
          if (response["error"] === true) {
            showError("Failed", response['message']);
            setLoading(false);
          } else {
            const data = await response.response.data;
            if (response["success"] === true) {
              showSuccess("Success!", "Login Successfully!");
              localStorage.setItem("userId", data.id);
              localStorage.setItem("selectedBusiness", data.bussiness_type);

              login();
              setTimeout(() => {
                navigate("/layout/dashboard");
              }, 1000);
              setLoading(false);
            } else {
              console.log("happy");
              showError("Failed", response.data.message);
              setLoading(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleForgotPassword = () => {
    navigate("/forgotpassword");
  };

  const handlePrivacyPolicy = () => {
    navigate("/privacy-policy");
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-grid">
      <div className="login-form">
        <div className="welcome-text">
          <img src={logo} alt="logo" className="logo" />
          <p style={{ fontSize: "40px", marginTop: "10px" }}>WELCOME</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-field">
            <TextField
              id="email"
              label="Username"
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              error={!!emailError}
              helperText={emailError}
              InputLabelProps={{
                style: { color: "black", fontSize: "14px" },
              }}
              className="wide-text-field"
            />
          </div>
          <div className="form-field">
            <TextField
              id="password"
              label="Password"
              variant="standard"
              type={showPassword ? "text" : "password"} // Toggle password visibility
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              error={!!passwordError}
              helperText={passwordError}
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={handleTogglePasswordVisibility}
                    aria-label="toggle password visibility"
                  >
                    {showPassword ? (
                      <VisibilityIcon style={{ color: "black" }} />
                    ) : (
                      <VisibilityOffIcon style={{ color: "black" }} />
                    )}
                  </Button>
                ),
              }}
              InputLabelProps={{
                style: { color: "black", fontSize: "14px" },
              }}
              className="wide-text-field"
            />
          </div>

          <div className="forgot-password">
            <a
              href="#"
              onClick={handleForgotPassword}
              className="forgot-password-link"
            >
              Forgot Password?
            </a>
          </div>
          <div className="forgot-password" onClick={handlePrivacyPolicy} style={{textAlign: 'left', cursor: 'pointer'}}>View Privacy Policy</div>
          <div className="form-field">
            <Button
              type="submit"
              variant="contained"
              fullWidth
              style={{
                background: "linear-gradient(180deg, #883DCF 0%, #451F69 100%)",
                color: "white",
              }}
              disabled={loading}
            >
              {/* {loading ? (
                <Hourglass
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="hourglass-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  colors={["white", "white"]}
                />
              ) : ( */}
              LOGIN
              {/* )} */}
            </Button>
          </div>
        </form>
      </div>
      <div className="login-image">
        <img src={loginoimg} alt="loginbg" />
      </div>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <Hourglass visible={true} height={50} width={50} />
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Login;
